export const Deadline: any = {
    first: {
        id: 1,
        value: 1,
    },
    second: {
        id: 3,
        value: 3,
    },
    third: {
        id: 4,
        value: 4,
    },
};








































































































































































































































































































































































































































// @ts-ignore
import VueSlickCarousel from 'vue-slick-carousel';
import Vue from 'vue';
import Map from './components/Map.vue';
import Modal from './components/Modal.vue';
import { Deadline } from '@/models/deadline';
import DepartmentsDropdown from './components/DepartmentsDropdown.vue';
import RelationDropdown from './components/RelationDropdown.vue';
import Axios from 'axios';

export default Vue.extend({
    name: 'Main',
    components: {
        Map,
        Modal,
        DepartmentsDropdown,
        RelationDropdown,
        VueSlickCarousel,
    },
    data() {
        return {
            carouselSettings: {
                responsive: [
                    {
                        breakpoint: 1924,
                        settings: {
                            arrows: false,
                            dots: true,
                            focusOnSelect: true,
                            infinite: true,
                            autoplay: true,
                            speed: 1000,
                            autoplaySpeed: 30000,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            touchThreshold: 5,
                            adaptiveHeight: true,
                        },
                    },
                ],
            },
            maxSelectedDepartments: 8, //Number maximum of departements that can be selected
            disabledBelgium: true, //If we want to disable Belgium from the departements selection
            currentStep: 1,
            showModalConditions: false,
            deadline: Deadline,
            selectedDepartments: [] as any[],
            selectedDeadline: null as typeof Deadline,
            infosForm: {
                gender: '',
                birthName: '',
                usageName: '',
                firstName: '',
                age: '',
            },
            contactForm: {
                lastName: '',
                name: '',
                email: '',
                phone: '',
                relation: '',
                conditions: '',
            },
            infosFormInvalidField: [] as any,
            contactFormInvalidField: [] as any,
        };
    },
    watch: {
        currentStep(val, oldVal) {
            if (val != oldVal) {
                if (val == 1 && this.selectedDepartments && this.selectedDepartments.length) {
                    setTimeout(() => {
                        this.$nextTick(() => {
                            let self = this;
                            this.selectedDepartments.forEach((department) => {
                                (self.$refs.departmentMap! as any).toggleDepartement(department);
                            });
                        });
                    }, 600);
                }
            }
        },
    },
    computed: {
        selectedFranceDepartments: function (): any {
            let department = this.selectedDepartments
                .filter((department) => {
                    if (department.isFrance) {
                        return true;
                    }
                })
                .map((d) => d.uniqueId);
            return department || [];
        },
        selectedBelgiumDepartments: function (): any {
            let department = this.selectedDepartments
                .filter((department) => {
                    if (!department.isFrance) {
                        return true;
                    }
                })
                .map((d) => d.uniqueId);
            return department || [];
        },
        disableGoToStep2: function (): any {
            return !this.selectedFranceDepartments.length && !this.selectedBelgiumDepartments.length;
        },
        disableGoToStep4: function (): any {
            return (
                !this.infosForm.gender || !this.infosForm.birthName || !this.infosForm.firstName || !this.infosForm.age
            );
        },
        disabledSendForm: function (): any {
            return (
                !this.contactForm.lastName ||
                !this.contactForm.name ||
                !this.contactForm.email ||
                !this.contactForm.phone ||
                !this.contactForm.conditions ||
                !this.contactForm.relation
            );
        },
        showUsageName: function (): any {
            return this.infosForm && this.infosForm.gender && this.infosForm.gender == 'Mme' ? true : false;
        },
        fullName: function (): string {
            return `${this.contactForm.lastName} ${this.contactForm.name},`;
        },
        currentStepClass: function (): string {
            return `step-${this.currentStep}`;
        },
    },
    methods: {
        departmentClicked(selectedDepartment: any) {
            if (
                selectedDepartment.isFrance &&
                this.selectedFranceDepartments.indexOf(selectedDepartment.uniqueId) != -1
            ) {
                this.selectedDepartments = this.selectedDepartments.filter(
                    (department) => department.uniqueId !== selectedDepartment.uniqueId
                );
                (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
                return;
            }

            if (
                !selectedDepartment.isFrance &&
                this.selectedBelgiumDepartments.indexOf(selectedDepartment.uniqueId) != -1
            ) {
                this.selectedDepartments = this.selectedDepartments.filter(
                    (department) => department.uniqueId !== selectedDepartment.uniqueId
                );
                (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
                return;
            }

            if (this.selectedDepartments.length < this.maxSelectedDepartments) {
                this.selectedDepartments.push(selectedDepartment);
                (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
                return;
            }
        },
        checkIfFormIsValid() {
            this.contactFormInvalidField = [];
            if (!this.disabledSendForm) {
                let frenchRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
                let belgiumRegex = /^(?:(?:\+|00)32|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

                if (!this.contactForm.phone.match(frenchRegex) && !this.contactForm.phone.match(belgiumRegex)) {
                    this.contactFormInvalidField.push('phone');
                }
                let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!this.contactForm.email.match(emailRegex)) {
                    this.contactFormInvalidField.push('email');
                }

                if (!this.contactFormInvalidField.length) {
                    return true;
                }
            }

            return false;
        },
        selectDeadline(deadline: any) {
            this.selectedDeadline = deadline;
            if (window.screen.width <= 1024) {
                this.currentStep = 3;
            }
        },
        goToStep4() {
            this.infosFormInvalidField = [];
            if (!this.disableGoToStep4) {
                if (
                    !Number.isInteger(this.infosForm.age) &&
                    !(parseInt(this.infosForm.age) >= 1 && parseInt(this.infosForm.age) <= 200)
                ) {
                    this.infosFormInvalidField.push('age');
                }

                if (!this.infosFormInvalidField.length) {
                    this.currentStep = 4;
                }
            }
        },
        async sendForm() {
            if (this.checkIfFormIsValid()) {
                let url = 'https://urgence-maison-de-retraite.fr/apiForm.php';
                let ipUser = await Axios.get('https://api.ipify.org');

                Axios.get(url, {
                    params: {
                        inputDep: this.selectedDepartments.map((d) => `${d.value} - ${d.name}`).join(','), // (1) 00 - Name (2+) 01 - Name1,02 - Name2
                        cnom: this.contactForm.lastName,
                        cprenom: this.contactForm.name,
                        cemail: this.contactForm.email,
                        contactlink: this.contactForm.relation, // relation.name
                        cphone: this.contactForm.phone,
                        consentementVal: this.contactForm.conditions ? 1 : 0, // 0 or 1
                        source: 'PRFF106',
                        emailAR: 'lead68',
                        userip: ipUser.data, // get IP from client

                        // Premium Fields
                        delai_urgence: this.selectedDeadline.value,
                        civilite_PA1: this.infosForm.gender,
                        paorigname: this.infosForm.usageName,
                        panom: this.infosForm.birthName,
                        paprenom: this.infosForm.firstName,
                        paydate: this.infosForm.age,
                    },
                }).then(
                    () => {
                        //Success
                        console.log('Success');
                        this.currentStep = 5;
                    },
                    () => {
                        //Error
                        console.log('Error');
                    }
                );
            }
        },
    },
});
